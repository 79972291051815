<template>
  <b-card
    class="salesorderitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="salesorderitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售订单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="salesorderitem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售订单编号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="salesorderitem.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="salesorderitem.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存编号"
            label-for="stock_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_no"
              size="sm"
              v-model="salesorderitem.stock_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存所在仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="salesorderitem.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存所在仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="salesorderitem.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="锁库ID"
            label-for="stocklock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stocklock_id"
              size="sm"
              v-model="salesorderitem.stocklock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单产品明细ID"
            label-for="purchase_orderitem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchase_orderitem_id"
              size="sm"
              v-model="salesorderitem.purchase_orderitem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="salesorderitem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="salesorderitem.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="salesorderitem.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="salesorderitem.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="salesorderitem.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类ID"
            label-for="category_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_id"
              size="sm"
              v-model="salesorderitem.category_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类名称"
            label-for="category_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_name"
              size="sm"
              v-model="salesorderitem.category_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌ID"
            label-for="brand_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_id"
              size="sm"
              v-model="salesorderitem.brand_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌名称"
            label-for="brand_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_name"
              size="sm"
              v-model="salesorderitem.brand_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="salesorderitem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否赠品"
            label-for="is_gift"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_gift"
              size="sm"
              v-model="salesorderitem.is_gift"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单价   product_cost_tax / (1 + tax_rate)"
            label-for="product_cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost"
              size="sm"
              v-model="salesorderitem.product_cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单价小计  product_cost * qty"
            label-for="product_cost_subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_subtotal"
              size="sm"
              v-model="salesorderitem.product_cost_subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="含税单价    product_cost + (product_cost * tax_rate )"
            label-for="product_cost_tax"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_tax"
              size="sm"
              v-model="salesorderitem.product_cost_tax"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="含税单价    product_cost + (product_cost * tax_rate )"
            label-for="product_cost_tax_subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_tax_subtotal"
              size="sm"
              v-model="salesorderitem.product_cost_tax_subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="税率"
            label-for="tax_rate"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="tax_rate"
              size="sm"
              v-model="salesorderitem.tax_rate"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="税额   product_cost_tax_subtotal -  product_cost_subtotal"
            label-for="tax_subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="tax_subtotal"
              size="sm"
              v-model="salesorderitem.tax_subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附加费"
            label-for="extra_cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="extra_cost"
              size="sm"
              v-model="salesorderitem.extra_cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附加费小计   extra_cost  *  qty"
            label-for="extra_cost_subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="extra_cost_subtotal"
              size="sm"
              v-model="salesorderitem.extra_cost_subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="金额"
            label-for="cost"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost"
              size="sm"
              v-model="salesorderitem.cost"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="价税合计 ( product_cost_tax+ extra_cost) * qty"
            label-for="subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="subtotal"
              size="sm"
              v-model="salesorderitem.subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="成本价  商品入库时的含税单价"
            label-for="cost_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost_price"
              size="sm"
              v-model="salesorderitem.cost_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="粗毛利    (product_cost_tax - cost_price) * qty"
            label-for="gross_profit"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="gross_profit"
              size="sm"
              v-model="salesorderitem.gross_profit"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="salesorderitem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="salesorderitem.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="salesorderitem.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="salesorderitem.modify_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesorderitemStore from './salesorderitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      salesorderitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('salesorderitem')) store.registerModule('salesorderitem', salesorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorderitem')) store.unregisterModule('salesorderitem')
    })

    const edit = function() {
      store.dispatch('salesorderitem/edit', {id: this.id}).then(res => {
        this.salesorderitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('salesorderitem/view', {id: this.id}).then(res => {
        this.salesorderitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('salesorderitem/save', this.salesorderitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-salesorderitem-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>